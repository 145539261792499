import React, { Component } from 'react';
import _ from 'lodash';
import $ from 'jquery';
import Draggable from 'react-draggable';
import {
  Cutout, Button, TabBody, Tab, Tabs
} from 'react95';

import MainWindowFooter from './additional/Footer';
import PopupWindow from './PopupWindow';

import WindowsList from './WindowsList';

import './MainWindow.css';

import aboutIcon from '../resources/icons/about.gif';
import contactIcon from '../resources/icons/contact.gif';
import projectsIcon from '../resources/icons/development.gif';
import trashIcon from '../resources/icons/trash.gif';
import emptyTrashIcon from '../resources/icons/empty_trash.gif';
import linksIcon from '../resources/icons/links.gif';
import musicIcon from '../resources/icons/music.gif';
import guestbookIcon from '../resources/icons/guestbook.png';
import loopTVIcon from '../resources/icons/loopTV.gif';
import websiteIcon from '../resources/icons/favicon.png';

import languages from '../resources/languages.json';

class MainWindowHeader extends Component {
  state = {
    programmingLanguage: undefined,
  }

  componentDidMount() {
    this.setState({
      programmingLanguage: Object.keys(languages).map(e => languages[e])[
        Math.floor(Math.random() * Object.keys(languages).map(e => languages[e]).length)
      ],
    });
  }

  render = () => {
    const { programmingLanguage } = this.state;

    return (
      <span>
        🚀 Portfolio
      </span>
    );
  }
}

class MainWindowBody extends Component {
  state = {
    windowsList: WindowsList(),
    iconsColliding: false,
    activeTab: 0
  }

  componentDidMount() {
    $('#computer_icon').bind('mouseup', this.triggerUp);
    $('#computer_icon').bind('touchend', this.triggerUp);
    document.addEventListener('keydown', this.closeTopWindow);
  }

  componentWillUnmount() {
    $('#computer_icon').unbind('mouseup', this.triggerUp);
    $('#computer_icon').unbind('touchend', this.triggerUp);
    document.removeEventListener('keydown', this.closeTopWindow);
  }

  openWindow = (windowName) => {
    const { windowsList } = this.state;
    _.set(windowsList, `${windowName}.opened`, true);
    this.focusWindow(windowName);
    this.setState({ windowsList });
  }

  closeWindow = (windowName) => {
    const { windowsList } = this.state;
    _.set(windowsList, `${windowName}.opened`, false);

    const currentWindow = document.getElementById(windowName);
    const backgroundWindow = currentWindow.previousSibling;
    this.focusWindow(backgroundWindow.id);

    this.setState({ windowsList });
  }

  isWindowOpened = (windowName) => {
    const { windowsList } = this.state;
    return _.get(windowsList, `${windowName}.opened`);
  }

  closeTopWindow = (event) => {
    if (event.keyCode === 27) {
      const parent = document.getElementById('windows-list');
      const domElements = Array.prototype.slice.call(parent.childNodes).slice().reverse();
      const topWindowDiv = domElements.find(element => element.hasChildNodes());

      if (topWindowDiv !== undefined) {
        this.closeWindow(topWindowDiv.id);
      }
    }
  }

  focusWindow = (chosenWindow) => {
    const chosenWindowDiv = document.getElementById(chosenWindow);
    const windowsContainer = document.getElementById('windows-list');
    const lastElement = windowsContainer.lastChild;

    const domElements = Array.prototype.slice.call(windowsContainer.childNodes).slice().reverse();
    const topWindowOpened = domElements.find(element => element.hasChildNodes());

    // make sure the window we chose is not already on top of the others
    if (chosenWindow !== lastElement.previousSibling.id || topWindowOpened === undefined) {
      windowsContainer.insertBefore(chosenWindowDiv, lastElement);

      const { windowsList } = this.state;
      _.set(windowsList, `${chosenWindowDiv.previousSibling.id}.focused`, false);
      _.set(windowsList, `${chosenWindowDiv.id}.focused`, true);
      this.setState({ windowsList });
    }
  }

  handleChangeTab = value => this.setState({ activeTab: value });

  renderPopupWindows() {
    const { windowsList } = this.state;

    return Object.keys(windowsList).map((window, index) => {
      const windowOpened = _.get(windowsList, `${window}.opened`);
      const windowFocused = _.get(windowsList, `${window}.focused`);
      const windowHeader = _.get(windowsList, `${window}.header`);
      const hasFullScreen = _.get(windowsList, `${window}.hasFullScreen`);
      const windowBody = _.get(windowsList, `${window}.body`);
      const windowTheme = _.get(windowsList, `${window}.windowTheme`);

      return <div
        key={ `${window}_${index}` }
        id={ window }
        onClick={ () => this.focusWindow(window) }
      >{
          windowOpened
            ? <PopupWindow
              closeWindow={ () => this.closeWindow(window) }
              focused={ windowFocused }
              header={ windowHeader }
              body={ windowBody }
              windowName={ window }
              displayExtraActions={ hasFullScreen }
              windowTheme={windowTheme}
            />
            : null
        }
      </div>;
    });
  }

  triggerUp = () => {
    const { iconsColliding } = this.state;
    const { onClickEgg } = this.props;

    if (iconsColliding) {
      onClickEgg();
    }
  }

  checkCollision = () => {
    const iconsColliding = this.divCollision($('#computer_icon'), $('#jancuk_icon'));

    if (iconsColliding) {
      this.setState({ iconsColliding });
    }
  }

  divCollision = (div1, div2) => {
    const x1 = div1.offset().left;
    const y1 = div1.offset().top;
    const h1 = div1.outerHeight(true);
    const w1 = div1.outerWidth(true);
    const b1 = y1 + h1;
    const r1 = x1 + w1;
    const x2 = div2.offset().left;
    const y2 = div2.offset().top;
    const h2 = div2.outerHeight(true);
    const w2 = div2.outerWidth(true);
    const b2 = y2 + h2;
    const r2 = x2 + w2;

    if (b1 < y2 || y1 > b2 || r1 < x2 || x1 > r2) return false;
    return true;
  }

  render() {
    const { onClickTV } = this.props;
    const { iconsColliding, activeTab } = this.state;
    const eggTriggered = sessionStorage.getItem('eggTriggered') === 'true';

    if (iconsColliding) {
      console.info('LINDAAAA?!?');
    }

    return (
      <div>
        <div id='windows-list'>{this.renderPopupWindows()}</div>
        <div>
        


      <Tabs value={activeTab} onChange={this.handleChangeTab} style={ {  } }>
        <Tab value={0}>Home</Tab>
        <Tab value={1}>About</Tab>
        <Tab value={2}>More</Tab>
        <Tab value={3}>Contact</Tab>
        
       
      </Tabs>
      <TabBody>
        <div style={ { marginTop: '-5px' } }>
          
            <ul style={ { display: activeTab === 0 ? 'block' : 'none' } }>
            <Cutout style={ { backgroundColor: '#e9e8ff' } }>
            <Button size='lg' square className='button-item' style={ { width: '85px', height: '85px', display: 'inline-block' } }
              onClick={ () => this.openWindow('projects') }
              active={ this.isWindowOpened('projects') }
            >
              <img src={ projectsIcon } className='icon' alt="projects"/>
              <figcaption className='icon-caption'>Projects</figcaption>
            </Button>
          
            
          
          </Cutout>
            </ul>

            <ul style={ { display: activeTab === 1 ? 'block' : 'none' } }>
            <Cutout style={ { backgroundColor: '#e9e8ff' } }>
            <Button size='lg' square className='button-item' style={ { width: '85px', height: '85px', display: 'inline-block' } }
              onClick={ () => this.openWindow('links') }
              active={ this.isWindowOpened('links') }
            >
              <img src={ linksIcon } className='icon' alt="links"/>
              <figcaption className='icon-caption'>Biografy</figcaption>
            </Button>
            </Cutout>
            </ul>
            <ul style={ { display:activeTab === 2 ? 'block' : 'none' } }>
            <Cutout style={ { backgroundColor: '#e9e8ff' } }>
            <Button size='lg' square className='button-item' style={ { width: '85px', height: '85px', display: 'inline-block' } }
              onClick={ () => this.openWindow('jancuk') }
              active={ this.isWindowOpened('jancuk') }>
              <img src={ trashIcon } className='icon' alt="jancuk"/>
              <figcaption className='icon-caption'>Random</figcaption>
            </Button>
          
            <Button size='lg' square className='button-item' style={ { width: '85px', height: '85px', display: 'inline-block' } }
              onClick={ onClickTV }
            >
              <img src={ loopTVIcon } className='icon' alt="projects"/>
              <figcaption className='icon-caption'>TL <span className='colored-text'>Vision</span></figcaption>
            </Button>
            </Cutout>
            </ul>
            <ul style={ { display:activeTab === 3 ? 'block' : 'none' } }>
            <Cutout style={ { backgroundColor: '#e9e8ff' } }>
            <Button size='lg' square className='button-item' style={ { width: '85px', height: '85px', display: 'inline-block' } }
              onClick={ () => this.openWindow('contact') }
              active={ this.isWindowOpened('contact') }
            >
              <img src={ contactIcon } className='icon' alt="contact"/>
              <figcaption className='icon-caption'>Contact</figcaption>
            </Button>
            <Button size='lg' square className='button-item' style={ { width: '85px', height: '85px', display: 'inline-block' } }
              onClick={ () => this.openWindow('guestbook') }
              active={ this.isWindowOpened('guestbook') }
            >
              <img src={ guestbookIcon } className='icon' alt="links"/>
              <figcaption className='icon-caption' style={ { fontSize: '14px' } }>Guestbook</figcaption>
            </Button>
            </Cutout>
            </ul>
           
          
          
        </div>
      </TabBody>


         
        </div>
        <MainWindowFooter onClick={ () => this.openWindow('credits') } active={ this.isWindowOpened('credits') }/>
      </div>
    );
  }
}

export { MainWindowHeader, MainWindowBody };
